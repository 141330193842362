import { INITIAL_DOCUMENT_ENQUIRY_DETAIL_COL_DEF, transferRowData } from "presentation/constant/DocumentEnquiry/DocumentEnquiryDetailColumnDefinition";
import { useDocumentEnquiryDetailTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo, useRef } from "react";

const DocumentEnquiryDetailTablePanel: React.FC = () => {
    const [docEnqDtlState] = useDocumentEnquiryDetailTracked();
    let gridRef: any = useRef();

    const memoDocEnqDtlTable = useMemo(() => {
        return <NbisTable
            id='document-enquiry-detail-table'
            columns={INITIAL_DOCUMENT_ENQUIRY_DETAIL_COL_DEF.slice()}
            data={transferRowData(docEnqDtlState.docDtlItems ?? [])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef}
            autoMarginLeft={true}
            entrypoint={'documentEnquiryDetail'} />;
    }, [docEnqDtlState.docDtlItems])

    return <>{<TableWrapper>{memoDocEnqDtlTable}</TableWrapper>}</>;
}

export default memo(DocumentEnquiryDetailTablePanel);