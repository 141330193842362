export interface DocumentEnquiryCriteria {
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,
    vesselName?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,
    invDateFrom?: Date | null,
    invDateTo?: Date | null,
    etdDateFrom?: Date | null,
    etdDateTo?: Date | null,
    billToCompList?: string[] | null,
    chgTypeList?: string[] | null,
    subChgTypeList?: string[] | null,
    stateList?: string[] | null,
    invNo?: string | null,
    handlingTmlList?: string[] | null,
    previewRefNo?: number | null,
    billingTypeList?: string[] | null,
    billingCycleCode?: string | null,
    billingCycleList?: string[] | null,

    oriDocNo?: string | null,
    docTypeList?: string[] | null,
    publicAtWebSiteList?: string[] | null,
    genDateFrom?: Date | null,
    genDateTo?: Date | null,
    issueByEdiList?: string[] | null,
    generatedBy?: string | null,
    issuedBy?: string | null,
    approvalStatusList?: string[] | null,
    approvalDatetimeFrom?: Date | null,
    approvalDatetimeTo?: Date | null,
    customerCodeList?: string[] | null,
    poNumber?: string | null,
    tariffTypeList?: string[] | null,
    tariffCodeList?: string[] | null,
    taxCodeList?: string[] | null,
    costCentreCode?: string | null,
    revenueCompanyCode?: string | null,
    productServiceCode?: string | null,
    interCompanyCode?: string | null,
    salesChannelCode?: string | null,
    revenueAcCode?: string | null,
    taxDateFrom?: Date | null,
    taxDateTo?: Date | null,
    taxPaymentTerm?: number | null,
    transactionType?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_DOCUMENT_ENQUIRY_CRITERIA: DocumentEnquiryCriteria = {
    co: "",
    vsl: "",
    voy: "",
    vesselName: null,
    opsDateFrom: null,
    opsDateTo: null,
    invDateFrom: null,
    invDateTo: null,
    etdDateFrom: null,
    etdDateTo: null,
    billToCompList: [],
    chgTypeList: [],
    subChgTypeList: [],
    stateList: [],
    invNo: "",
    handlingTmlList: [],
    previewRefNo: null,
    billingTypeList: [],
    billingCycleCode: "",
    billingCycleList: [],
    genDateFrom: null,
    genDateTo: null,
    oriDocNo: '',
    docTypeList: [],
    generatedBy: null,
    issuedBy: null,
    publicAtWebSiteList: [],
    issueByEdiList: [],
    approvalDatetimeFrom: null,
    approvalDatetimeTo: null,
    approvalStatusList: [],
    customerCodeList: [],
    poNumber: null,
    tariffTypeList: [],
    tariffCodeList: [],
    taxCodeList: [],
    costCentreCode: null,
    revenueCompanyCode: null,
    productServiceCode: null,
    interCompanyCode: null,
    salesChannelCode: null,
    revenueAcCode: null,
    taxDateFrom: null,
    taxDateTo: null,
    taxPaymentTerm: null,
    transactionType: null
}