export interface EnabledDocumentEnquiryCriteria {
    all: boolean,
    voyage: boolean,
    charge: boolean,
    container: boolean,
    document: boolean,
    tariffCoa: boolean,

    // Voyage
    coVslVoy: boolean,
    vesselName: boolean,
    etd: boolean,

    // Charge
    opsDate: boolean,
    billToCompList: boolean,
    chgTypeList: boolean,
    subChgTypeList: boolean,
    billingTypeList: boolean,
    billingCycleList: boolean,
    billingCycleCode: boolean,
    poNumber: boolean,

    //Container
    handlingTmlList: boolean,

    // Document
    invDate: boolean,
    invNo: boolean,
    oriDocNo: boolean,
    docTypeList: boolean,
    stateList: boolean,
    publicAtWebSiteList: boolean,
    genDate: boolean,
    previewRefNo: boolean,
    issueByEdiList: boolean,
    generatedBy: boolean,
    issuedBy: boolean,
    approvalStatusList: boolean,
    approvalDatetime: boolean,
    transactionType: boolean,
    customerCodeList: boolean,
    taxCodeList: boolean,
    taxDate: boolean,
    taxPaymentTerm: boolean,

    //Tariff/Coa
    tariffCodeList: boolean,
    tariffTypeList: boolean,
    costCentreCode: boolean,
    revenueCompanyCode: boolean,
    productServiceCode: boolean,
    interCompanyCode: boolean,
    salesChannelCode: boolean,
    revenueAcCode: boolean,

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA: EnabledDocumentEnquiryCriteria = {
    all: false,
    voyage: true,
    charge: true,
    container: true,
    document: true,
    tariffCoa: true,
    coVslVoy: true,
    vesselName: false,
    opsDate: true,
    invDate: true,
    etd: true,
    billToCompList: true,
    chgTypeList: false,
    subChgTypeList: false,
    billingTypeList: false,
    billingCycleList: false,
    billingCycleCode: false,
    poNumber: false,
    handlingTmlList: false,
    invNo: true,
    oriDocNo: true,
    docTypeList: true,
    stateList: true,
    publicAtWebSiteList: false,
    genDate: true,
    previewRefNo: true,
    issueByEdiList: false,
    generatedBy: true,
    issuedBy: true,
    approvalStatusList: true,
    approvalDatetime: false,
    transactionType: false,
    customerCodeList: true,
    taxCodeList: false,
    taxDate: false,
    taxPaymentTerm: false,
    tariffCodeList: true,
    tariffTypeList: true,
    costCentreCode: false,
    revenueCompanyCode: false,
    productServiceCode: false,
    interCompanyCode: false,
    salesChannelCode: false,
    revenueAcCode: false
}