
import { PrintQueueEntity } from "domain/entity/Common/PrintQueueEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { PrintQueueRepository } from "./PrintQueue";

export const PrintQueueRepoImpl = (): PrintQueueRepository => {
    const url = '/v1/printQueue';
    const forComboxUrl = '/v1/printQueueForCombox';

    const getAllPrintQueues = async (): Promise<PrintQueueEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });

    }

    const getPrintQueueByKey = async (key: string): Promise<PrintQueueEntity> => {
        return axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const getPrintQueueByType = async (type: string): Promise<PrintQueueEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${url}/PrintQueueType/${type}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const createNewPrintQueue = async (newData: PrintQueueEntity): Promise<PrintQueueEntity> => {
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData);
    }

    const updatePrintQueue = async (updatedData: PrintQueueEntity): Promise<PrintQueueEntity> => {
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData);
    }

    const deletePrintQueueByKey = async (key: string): Promise<boolean> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key).then(res => {
            return res.success;
        }).catch(error => {
            return false
        })
    }

    const getPrintQueueForCombox = async (): Promise<PrintQueueEntity[]> => {
        return axiosGetData(referenceAxiosInstance, `${forComboxUrl}`, []).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    return {
        getAllPrintQueues: getAllPrintQueues,
        getPrintQueueByKey: getPrintQueueByKey,
        getPrintQueueByType: getPrintQueueByType,
        createNewPrintQueue: createNewPrintQueue,
        updatePrintQueue: updatePrintQueue,
        deletePrintQueueByKey: deletePrintQueueByKey,
        getPrintQueueForCombox: getPrintQueueForCombox,
    }
}