import { useDocumentEnquiryDetailVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryDetailVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { DocumentEnquiryHeaderFieldFormPanel } from "./Form/DocumentEnquiryHeaderFieldFormPanel";
import DocumentEnquiryDetailTablePanel from "./Table/DocumentEnquiryDetailTablePanel";


const DocumentEnquiryDetailMaintenance:React.FC = () => {

    const docEnqDetailVM = useDocumentEnquiryDetailVM();
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const [isLoading, setIsLoading] = useState(true);
    
    // const initialRedirectScreen = useCallback(async() => {
    //     const redictHeader = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER)?
    //         JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER)??"") as DocumentHeaderEntity :
    //         null;
    //     if (!redictHeader) return;
    //     docEnqDetailVM.onShowLoading();

    //     await Promise.allSettled([
    //         docEnqDetailVM.onSearchDocHdrById(redictHeader.id??0).then(data => {
    //             if (data) {
    //                 const docHdr = data;
    //                 setDocumentEnquiryDetailState(prevState => ({
    //                     ...prevState,
    //                     docHdr: {
    //                         ...prevState.docHdr,
    //                         ...docHdr
    //                     },
    //                     isShowCloseIcon: false
    //                 }));
    //                 docEnqDetailVM.onPageInit(docHdr);
    //                 docEnqDetailVM.onSearch(docHdr.id??0).then(() => {
    //                     docEnqDetailVM.onHideLoading();
    //                     setInitial(false);
    //                 }).catch(error => {                        
    //                     docEnqDetailVM.onHideLoading();
    //                     setInitial(false);
    //                 }).finally(() => {                        
    //                     localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER);
    //                 })
    //             }
    //         }),
    //     ]).catch(error => {            
    //         docEnqDetailVM.onHideLoading();
    //         setInitial(false);            
    //         localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER);
    //     });
        
        
    // }, [docEnqDetailVM, setDocumentEnquiryDetailState])

    // useEffect(() => {
    //     const onDocumentEnquiryDetailRedirect = async(e:any) => {
    //         switch (e.detail.action) {
    //             case E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER:                    
    //                 const manSysCharge = (e.detail.data.data as ManualSystemChargeEntity);
    //                 localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER, JSON.stringify(manSysCharge));
    //                 setInitial(true);

    //                 break;
    //         }
    //     }

    //     customEventListener(E_Type_Of_Event.DOCUMENT_ENQUIRY_DETAIL_REDIRECT_EVENT_INITIAL, onDocumentEnquiryDetailRedirect);
    // }, [docEnqDetailVM, docEnqDetailVM, setDocumentEnquiryDetailState]);

    // useEffect(() => {
    //     initialRedirectScreen();   
    // }, [initialRedirectScreen])

    // useEffect(()=>{
    //     docEnqDetailVM.onShowLoading();
    //     docEnqDetailVM.onPageInit(docEnquiryState.currentHdr);
    //     docEnqDetailVM.onSearch(docEnquiryState.currentHdr?.id??0).then(() => {
    //         docEnqDetailVM.onHideLoading();
    //     }).catch(error => {                        
    //         docEnqDetailVM.onHideLoading();
    //     }).finally(() => {                        
    //     })
        
    // },[docEnqDetailVM, docEnquiryState.currentHdr])

    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                    docEnqDetailVM.onPageInit(docEnquiryState.currentHdr),
                    docEnqDetailVM.onSearch(docEnquiryState.currentHdr?.id??0)
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if(index === 1 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [docEnqDetailVM, docEnquiryState.currentHdr])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        {/* <div className="main-comp-wrapper"> */}
            {/* <DocumentEnquiryDetailTitleBar/> */}
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"38%"}
                rightSectionWidth={"62%"}
                leftChildren={<DocumentEnquiryHeaderFieldFormPanel/>}
                rightChildren={<DocumentEnquiryDetailTablePanel/>}
            /> 
        {/* </div> */}
    </>
}

export default memo(DocumentEnquiryDetailMaintenance)