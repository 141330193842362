import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentEnquiryProvider } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { DocumentEnquiryComp } from "presentation/view/section/DocumentEnquiry/DocumentEnquiryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const DocumentEnquiryContainer = () => <ANAInfoWrapper permission={Permission.LOGIN}>
    <MessageBarWrapper>
        <DocumentEnquiryProvider>
            <GridStyles />
            <DocumentEnquiryComp />
        </DocumentEnquiryProvider>
    </MessageBarWrapper>
</ANAInfoWrapper>;