import { IMessage } from "@stomp/stompjs";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { DocumentHeaderEntity } from "domain/entity/Document/DocumentHeaderEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { ChargeDataEnquiryRepository } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { RemoteFileInfoRepository } from "domain/repository/Common/RemoteFileInfoRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { DocumentEnquiryRepository } from "domain/repository/Document/DocumentEnquiryRepo";
import { DocumentPreviewRepository } from "domain/repository/DocumentPreview/DocumentPreviewRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { DomUtils } from "helpers/DomUtils";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import _, { parseInt } from "lodash";
import { SearchPreferenceType } from "presentation/constant/Common/SearchPreferenceType";
import { CriteriaDirectoryAllCheckboxOption, CriteriaDirectoryCheckboxOptionList } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryCriteria, EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { DocumentEnquiryCriteriaCheckboxRelatedField } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteriaCheckboxRelatedField";
import { DocumentEnquiryPrintCriteria, EMPTY_DOCUMNET_PRINT_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryPrintCriteria";
import { DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA, EnabledDocumentEnquiryCriteria } from "presentation/constant/DocumentEnquiry/EnabledDocumentEnquiryCriteria";
import { EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA } from "presentation/constant/DocumentPreview/DocumentPreviewFirstPrintCriteria";
import { ParameterCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { DocumentEnquiryModel } from "presentation/model/DocumentEnquiry/DocumentEnquiryModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

interface DocumentEnquiryVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentEnquiryModel>> | ((value: SetStateAction<DocumentEnquiryModel>) => void),
    ]
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    chargeDataEnquiryRepo: ChargeDataEnquiryRepository,
    searchPreferenceRepo: SearchPreferenceRepository<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>,
    customerRepo: CustomerRepository,
    docEnquiryRepo: DocumentEnquiryRepository,
    printQueueRepo: PrintQueueRepository,
    remoteFileInfoRepo: RemoteFileInfoRepository,
    docPreviewRepo: DocumentPreviewRepository,
}

export const DocumentEnquiryVM = ({ dispatch, companyRepo, chargeTypeRepo, 
     parameterDetailRepo,  searchPreferenceRepo,customerRepo,docEnquiryRepo,printQueueRepo, remoteFileInfoRepo ,docPreviewRepo, standardTariffCodeRepo}: DocumentEnquiryVMProps) => {
    const [docEnquiryDispatch] = dispatch;

    const onRefresh = () => {
        docEnquiryDispatch((prevState) => {
            const gruopStateList = prevState.currentTableRef?.current?.api.getServerSideGroupLevelState();
            gruopStateList?.forEach((groupState: any) => {
                prevState.currentTableRef?.current?.api.refreshServerSide({ route: groupState.route, purge: false })
            })
            return {
                ...prevState
            }
        })
    };

    const onWSMessage = (msg: IMessage) => {
        const body = JSON.parse(msg.body) as ManualSystemChargeEntity;
        docEnquiryDispatch((prevState) => {
            prevState.currentTableRef?.current?.api.forEachNode((rowNode: any) => {
                if (body.cntrNo === rowNode.data.cntrNo) {
                    rowNode.setData(body);
                }
            });
            return {
                ...prevState
            }
        })
    }

    const onCriteriaDirectoryDropdown = (e: any) => {
        docEnquiryDispatch(prevState => {
            prevState.checkboxRef[e?.value]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
              })
            return {
                ...prevState,
                highlightedCheckboxKey: e?.value ?? e
            }
        })
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeDocumentEnquiry'))
    }

    const openContainerDetail = (docHdr: DocumentHeaderEntity) => {
        const objDispatch: { [x: string]: boolean } = { isFullScreen: true };
        customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_DETAIL_SHOW, objDispatch);
        docEnquiryDispatch(prevState => ({
            ...prevState,
            isShowContainerDetail: true,
            currentHdr: docHdr
        }))
    }

    const closeContainerDetail = () =>{
        customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_DETAIL_HIDE, {});
        docEnquiryDispatch(prevState => ({
            ...prevState,
            isShowContainerDetail: false,
        }))
    }

    const updateCheckboxRef = (checkboxRef: { [key: string]: HTMLElement | null }) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            checkboxRef: checkboxRef
        }))
    }

    const updateCheckboxValue = (checked: boolean, key: string) => {
        docEnquiryDispatch(prevState => {
            if (key === CriteriaDirectoryAllCheckboxOption.key) {
                CriteriaDirectoryCheckboxOptionList.forEach(item => {
                    item.checkboxList?.forEach(item => {
                        prevState.checkboxValue[item.key] = checked
                    })
                })
            }
            CriteriaDirectoryCheckboxOptionList.find(item => item.option.key === key)?.checkboxList?.forEach(item => {
                prevState.checkboxValue[item.key] = checked
            })
            prevState.checkboxValue[key] = checked
            return {
                ...prevState,
                highlightedCheckboxKey: null
            }
        })
    }

    const onSaveCheckboxValue = () => {
        docEnquiryDispatch(prevState => {
            const searchCriteriaFieldName = Object.keys(EMPTY_DOCUMENT_ENQUIRY_CRITERIA).concat(Object.keys(DocumentEnquiryCriteriaCheckboxRelatedField))
            const disabledCriteria = Object.keys(prevState.checkboxValue).filter((item) => !prevState.checkboxValue[item] && searchCriteriaFieldName.includes(item))

            const resetSearchCriteria = disabledCriteria.reduce((obj, fieldName) => {
                //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
                //If related field found, we use related field to retrieve default value.
                let resetSearchCriteriaPerField = { [fieldName]: EMPTY_DOCUMENT_ENQUIRY_CRITERIA[fieldName] }
                if (DocumentEnquiryCriteriaCheckboxRelatedField[fieldName]) {
                    resetSearchCriteriaPerField = DocumentEnquiryCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                        return {
                            ...objPerField,
                            [fieldNamePerField]: EMPTY_DOCUMENT_ENQUIRY_CRITERIA[fieldNamePerField],
                        }
                    }, {})
                }
                return {
                    ...obj,
                    ...resetSearchCriteriaPerField
                };
            }, {});

            return {
                ...prevState,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteria },
                searchCounter: prevState.searchCounter !== 0 ? prevState.searchCounter + 1 : 0,
                enabledSearchCriteria: prevState.checkboxValue,
                isFilterSearchCriteria: true,
                isEditSearchCriteria: true,
            }
        })
    }

    const onResetCheckboxValue = () => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                checkboxValue: { ...DEFAULT_ENABLED_DOCUMENT_ENQUIRY_CRITERIA },
            }
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_DOCUMENT_ENQUIRY_CRITERIA[fieldName] }
        if (DocumentEnquiryCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = DocumentEnquiryCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_DOCUMENT_ENQUIRY_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        docEnquiryDispatch(prevState => {        
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField },
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        DomUtils.addOnlyDocSearchClass();
        docEnquiryDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_EDIT_CRITERIA_BACK, { isEdit: false, enablePrint: false, selectedRows: [], enableRePrint:false });
            return {
                ...prevState, 
                searchCounter: 0, 
                searchCriteria: EMPTY_DOCUMENT_ENQUIRY_CRITERIA,
            }
        })
    }

    const onHideRightCriteriaPanel = () => {
        docEnquiryDispatch(prevState => {
            if (prevState.isShowRightCriteriaPanel) {
                customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_HIDE_RIGHT_PANEL, {});
                DomUtils.removeWithDocSearchClass();
            } else {
                customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_HIDE_RIGHT_PANEL, { isDirectory: !prevState.isFilterSearchCriteria });
                DomUtils.addWithDocSearchClass();
            }

            return { ...prevState, isShowRightCriteriaPanel: !prevState.isShowRightCriteriaPanel, };
        });
    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = () => {
        DomUtils.addWithDocSearchClass();
        docEnquiryDispatch(prevState => {
            if (prevState.searchCounter === 0) DomUtils.addOnlyDocSearchClass();
            else DomUtils.addWithDocSearchClass();
            return { ...prevState, isShowRightCriteriaPanel: true, isFilterSearchCriteria: true, isEditSearchCriteria: true, }
        });
    }

    // Open directory criteria panel not search criteria preview panel
    const onSwitchSearchCriteriaMode = () => {
        DomUtils.addWithDocSearchClass();
        docEnquiryDispatch(prevState => {
            if (prevState.searchCounter === 0) DomUtils.addOnlyDocSearchClass();
            else DomUtils.addWithDocSearchClass();

            return {
                ...prevState, isShowRightCriteriaPanel: true, isFilterSearchCriteria: false, isEditSearchCriteria: false, checkboxValue: prevState.enabledSearchCriteria, highlightedCheckboxKey: null,
            };
        });
    }

    const onSearch = async(counter?:number) => {
        DomUtils.addWithDocSearchClass();

        docEnquiryDispatch(prevState => {

            const tempCounter = counter??prevState.searchCounter;

            const obj: { [x: string]: boolean } = {};
            const objDispatch: { [x: string]: boolean } = { isEdit: true };
            if (tempCounter === 0) {
                obj.isShowRightCriteriaPanel = true;
                objDispatch.isEdit = true;
                obj.isEditSearchCriteria = false;
            } else {
                obj.isEditSearchCriteria = !prevState.isEditSearchCriteria;
            }

            customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_EDIT_CRITERIA_BACK, objDispatch);
            return { ...prevState, ...obj, searchCounter: tempCounter + 1 };
        }); 
    }

    const fetchTableData = async (searchCriteria: DocumentEnquiryCriteria, searchModeEntity: SearchModeEntity, setCriteria:boolean=false):Promise<any> => {
        DomUtils.addWithDocSearchClass();

        await docEnquiryRepo.searchDocument(searchCriteria).then((data) => {
            docEnquiryDispatch(prevState => {
                customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_GET, {
                    docEnqModel: {
                        ...prevState,
                        isShowLegendButton: true,
                    }
                });
                if (!setCriteria) {
                    return {
                        ...prevState,
                        docHdrs: data,
                    }
                } else {
                    return {
                        ...prevState,
                        docHdrs: data,
                        searchCriteria: searchCriteria,
                    }
                }
            })
            return data;
        }).catch((error) => {
            docEnquiryDispatch(prevState => {
                return {
                    ...prevState,
                    docHdrs: [],
                }
            })
            return "error";
        });
    }

    const loadDropdownOption = async() => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                        chgOnCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                let subChargeTypeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chgTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChgTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.HANDLING_TERMINAL).then(
            parDtls => {
                const handlingTmlDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        handlingTmlDropdownOptions: handlingTmlDropdownOptions
                    }
                }))
            }
        )

        await customerRepo.getAllCustomers().then(
            customers => {
                let customerCodeDropdownOptions = customers?.map((customer) => ({
                    dropdownLabel: customer.customerCode + '-' + customer.customerDesc1,
                    tagLabel: customer.customerCode,
                    value: customer.customerCode,
                })) ?? []
                customerCodeDropdownOptions = _.orderBy(customerCodeDropdownOptions, "value");

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: [
                            ...customerCodeDropdownOptions],
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.BILLING_CYCLE).then(
            parDtls => {
                const billingCycleCheckBoxOptions = parDtls?.map((parDtl) => ({
                    key: parDtl.parameterDtlCode,
                    name: parDtl.parameterDtlCode,
                })) ?? []

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicGroupCheckboxs: {
                        ...prevState.dynamicGroupCheckboxs,
                        billingCycleCheckBoxOptions: billingCycleCheckBoxOptions
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};
                let allTariffCodeeDropdownOptions:DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t => 
                        t.value === tariff.tariffCode);

                    if(!isTariffCodeExisted){
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })            

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,                        
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,                        
                        allTariffCodeeDropdownOptions: allTariffCodeeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.TAX_CODE).then(
            parDtls => {
                const taxCodeDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode
                })) ?? []

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        taxCodeDropdownOptions: taxCodeDropdownOptions
                    }
                }))
            }
        )
    }
    
    const onDocumentActive = () => {
        docEnquiryDispatch(prevState => ({
            ...prevState, documentActive: !prevState.documentActive
        }))
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    ((fieldName === "subChgTypeList" || fieldName === "tariffCodeList") ? [] : '') : e),
            }
        }))
    }

    const onModalDropdownChange = (e: any, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            docPrintCriteria: {
                ...prevState.docPrintCriteria,
                [fieldName]: e?.value 
            }
        }))
    }

    const onDatePickerChange = (value: Date, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: value,
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: checked,
            }
        }))
    }

    const onModalCheckboxChange = (checked: boolean, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            docPrintCriteria: {
                ...prevState.docPrintCriteria,
                [fieldName]: checked,
            }
        }))
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[],fieldName:string) => {
        let selectedValue:string[] = [];
        if (e) {
            e.forEach(function(value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                [fieldName]: selectedValue,
            }
        }));
    };

    const onModalGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[],fieldName:string) => {
        let selectedValue:string[] = [];
        if (e) {
            e.forEach(function(value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        docEnquiryDispatch(prevState => ({
            ...prevState,
            docPrintCriteria : {
                ...prevState.docPrintCriteria,
                [fieldName]: selectedValue,
            }
        }));
    };


    const onMultipleCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldNames: string[]) => {
        const value = fieldNames.reduce((obj, fieldName) => {
            return {
                ...obj,
                [fieldName]: !!e.find((checkboxProp) => checkboxProp?.key === fieldName),
            };
        }, {});
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...value
            }
        }))
    }

    const onRadioChange = (e: any, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.key
            }
        }))
    };

    const onSliderRangeChange = (e: string, fieldName: string) => {
        const inputData = e ?? ''
        const inputDataInNumber = inputData === '' ? null : parseInt(inputData)
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: isNaN(inputDataInNumber ?? 0) ? prevState.searchCriteria[fieldName] : inputDataInNumber,
            }
        }))
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val:any = e.target.value.toString();

        docEnquiryDispatch(prevState => {
            //if(fieldName === 'previewRefNo' || fieldName=== 'taxPaymentTerm'){
            const reg = /^\d*$/;
            if (!reg.test(val)) {
                if (fieldName === 'previewRefNo') {
                    val = prevState.searchCriteria.previewRefNo;
                } else if (fieldName === 'taxPaymentTerm') {
                    val = prevState.searchCriteria.taxPaymentTerm;
                }
            }
            /*}else*/
             if(fieldName === 'invNo' || fieldName === 'oriDocNo')  {
                val = val.toUpperCase().replace(/\s+/g, '');
            }
            
            return ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        })})
    };

    const onModalInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val:any = e.target.value.toString();

        docEnquiryDispatch(prevState => {
            if(fieldName === 'invOrigCopies' || fieldName === 'suppDocCopies'){
                const reg =  /^\d*$/;
                if(!reg.test(val)){
                    val = prevState.docPrintCriteria[fieldName];
                }
            }
            
            return ({
            ...prevState,
            docPrintCriteria: {
                ...prevState.docPrintCriteria,
                [fieldName]: val,
            }
        })})
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'cntrList' ? 
                    (e.target.value&&e.target.value.toString().includes(",") ? e.target.value.toString().split(",") : [e.target.value.toString()]) : 
                    e.target.value.toString(),
            }
        }))
    };

    const onExpandableToggleChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value
            }
        }))
    };

    // Save new preference
    const openSaveNewConfirmModal = () => {
        docEnquiryDispatch(prevState => ({ ...prevState, isShowSaveNewConfirmModal: true, }));
    };

    const openDeleteConfirmModal = () => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            isShowDeleteConfirmModal: true,
        }))
    };

    const closeConfirmModal = () => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            isShowSaveNewConfirmModal: false,
            isShowDeleteConfirmModal: false,
            isShowPrintPad: false,
        }))
    };

    const setUserEmail = (userEmail: string) => {
        docEnquiryDispatch(prevState => ({
            ...prevState,
            userEmail: userEmail
        }))
    }

    const saveNewSearchPreference = async (userEmail: string, searchCriteria: DocumentEnquiryCriteria, enabledSearchCriteria: EnabledDocumentEnquiryCriteria, preferenceName: string) => {
        let saveSuccess = true
        let result: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria> | null = null
        try {
            result = await searchPreferenceRepo.createNewSearchPreference({
                key: userEmail + preferenceName,
                versionIdentifier: {},
                userEmail: userEmail,
                preferenceName: preferenceName,
                searchCriteria: searchCriteria,
                criteriaDirectory: enabledSearchCriteria,
                type: SearchPreferenceType.DOC_ENQ_CRITERIA
            })
            if (_.isEmpty(result)) {
                saveSuccess = false
            }
        } catch (e: any) {
            saveSuccess = false
        }

        docEnquiryDispatch(prevState => {
            const obj: DocumentEnquiryModel = {
                ...prevState,
                ...saveSuccess && {
                    isShowSaveNewConfirmModal: false,
                    isShowDeleteConfirmModal: false,
                    currentSearchPreference: result
                }
            };
            customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: result });
            return { ...obj };
        })

        loadAllSearchPreference(userEmail)
    }

    const loadAllSearchPreference = async(userEmail: string) => {
        await searchPreferenceRepo.getSearchPreferencesByEmailAndType(userEmail,SearchPreferenceType.DOC_ENQ_CRITERIA).then(
            searchPreferences => {
                docEnquiryDispatch(prevState => {
                    const dataSearchPreferences: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>[] = searchPreferences?.filter(searchPreference => searchPreference.userEmail === prevState.userEmail) ?? [];
                    customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_GET, {
                        docEnqModel: {
                            ...prevState,
                            searchPreferences: [...dataSearchPreferences],
                        }
                    });
                    return {
                        ...prevState,
                        searchPreferences: [...dataSearchPreferences]
                    };
                })
            }
        )
    }

    const loadSearchPreference = (searchPreference: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria>) => {
        docEnquiryDispatch(prevState => {
            const obj: DocumentEnquiryModel = {
                ...prevState,
                currentSearchPreference: searchPreference,
                enabledSearchCriteria: { ...searchPreference.criteriaDirectory },
                checkboxValue: { ...searchPreference.criteriaDirectory },
                searchCriteria: { ...EMPTY_DOCUMENT_ENQUIRY_CRITERIA, ...searchPreference.searchCriteria },
            };
            customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: searchPreference });
            return { ...obj };
        })
    }

    const saveSearchPreference = async (currentSearchPreference: SearchPreferenceEntity<DocumentEnquiryCriteria,EnabledDocumentEnquiryCriteria> | null/*, searchCriteria: DocumentEnquiryCriteria, enabledSearchCritieria: EnabledDocumentEnquiryCriteria*/) => {
        /*if (currentSearchPreference?.key) {
            try {
                await searchPreferenceRepo.updateSearchPreference({
                    ...currentSearchPreference,
                    searchCriteria: searchCriteria,
                    criteriaDirectory: enabledSearchCritieria,
                }).then(data => {
                    docEnquiryDispatch(prevState => {
                        const obj: DocumentEnquiryModel = {
                            ...prevState,
                                currentSearchPreference: data
                        };
                        customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: data });
                        return { ...obj };
                    })
                })
            } catch (e: any) {
            }
        }*/
        docEnquiryDispatch(prevState => {
            let obj: DocumentEnquiryModel = { ...prevState }
            if (currentSearchPreference?.key) {
                try {
                    searchPreferenceRepo.updateSearchPreference({
                        ...currentSearchPreference,
                        searchCriteria: prevState.searchCriteria,
                        criteriaDirectory: prevState.enabledSearchCriteria,
                    }).then(data => {
                        obj = {
                            ...prevState,
                            currentSearchPreference: data
                        };
                        customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: data });
                    })
                } catch (e: any) {
                }
            }
            return { ...obj };
        })    
    }

    const deleteSearchPreference = async (userEmail: string, preferenceName: string) => {
        try {
            await searchPreferenceRepo.deleteSearchPreferenceByKey(userEmail + "-" + preferenceName).then(data => {
                docEnquiryDispatch(prevState => {
                    const obj: DocumentEnquiryModel = {
                        ...prevState,
                        isShowSaveNewConfirmModal: false,
                        isShowDeleteConfirmModal: false,
                        currentSearchPreference: prevState.currentSearchPreference?.preferenceName === preferenceName ? null : prevState.currentSearchPreference
                    };
                    customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: prevState.currentSearchPreference?.preferenceName === preferenceName ? null : prevState.currentSearchPreference });
                    return { ...obj };
                })
            })
        } catch (e: any) {
        }        

        loadAllSearchPreference(userEmail)
    }

    const showLoading = () => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true
            }
        })
    }

    const hideLoading = () => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false
            }
        })
    }

    const updateSelectedRows = (rows:any[]) => {
        let enableRePrint = true;
        const docNos: string[] = rows.map(hdr => hdr?.docNo).filter((docNo): docNo is string  => docNo === null);
        if(docNos.length > 0){
            enableRePrint = false;
        }
        const objDispatch: { [x: string]: any } = { enablePrint: !_.isEmpty(rows), selectedRows: rows, enableRePrint:enableRePrint};
        customDispatchEvent(E_Type_Of_Event.DOCUMENT_ENQUIRY_EVENT, E_Custom_Dispatch_Event.DOCUMENT_ENQUIRY_ENABLE_PRINT, objDispatch);
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows                       
            }
        })
    }

    const onClosePrintModal = () => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isShowPrintPad: false,
                docPrintCriteria: EMPTY_DOCUMNET_PRINT_CRITERIA                       
            }
        })
    }

    const onReprint = async (docHdrs : DocumentHeaderEntity[], docPrintCriteria: DocumentEnquiryPrintCriteria) => {

        let message;
        const docHdrIds: number[] = docHdrs.map(hdr => hdr?.id).filter((id): id is number  => id !== null);
        await docEnquiryRepo.reprintDoc({
                    docPrintCriteria,
                    docHdrIds,
            }).then((res) => {                
                if(!res.success){
                    message = "Print/Send failed.";
                }                
        }).catch((error) => {
            message = "Print/Send failed."
        })   
        onClosePrintModal();
        return {"printReportFail": message}
    }

    const onPreview = async (docHdrs : DocumentHeaderEntity[],isSupporting: boolean, isDownload: boolean) => {
        const docHdrIds: number[] = docHdrs.map(hdr => hdr?.id).filter((id): id is number  => id !== null);
        const docPrvEtys: DocumentPreviewEntity[] = docHdrIds.map(hdrId => {
            return {id: hdrId};
        })

        let errorMsg;
        if(isSupporting){
            await docPreviewRepo.previewSupportingReport({
                searchCriteria: EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA,
                firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
                docHdrList: docPrvEtys,
                docDtlList: [],
                invoiceItemDtlList: [],
                isDocNoBackDateFlag: "N"
            }).then((data)=>{
                const isPreviewRpt = prevOrDownloadRpt(data,isDownload); 

                if(!isPreviewRpt){
                    docEnquiryDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"previewReportFail":"Invoice/Credit Note and/or Supporting Reports cannot be found."}    
                        }
                    });
                    
                    errorMsg = "Invoice/Credit Note and/or Supporting Reports cannot be found.";
                }
            }).catch((error) => {
                docEnquiryDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"previewReportFail":"preview supporting report error."}    
                    }
                });

                errorMsg = "Preview supporting report error.";
            })

            return {"previewReportFail": errorMsg};
        }else{
            await docPreviewRepo.previewDocReport({
                searchCriteria: EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA,
                firstPrintCriteria: {...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA},
                docHdrList: docPrvEtys,
                docDtlList: [],
                invoiceItemDtlList: [],
                isDocNoBackDateFlag: "N"
            }).then((data)=>{
                prevOrDownloadRpt(data,isDownload); 
            })
        }
     }
 

    const showPrintPad = () => {
        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isShowPrintPad: true
            }
        })
    }

    const prevOrDownloadRpt = (data: any, isDownloadRpt: boolean) => {
        if(data != null){                        
            for (const key in data) {
            
                if (data[key] != null && data[key] !== undefined) {
                    const tempData = data[key] as string;
                
                    let base64 = tempData
                    let bstr = atob(base64)
                    let n = bstr.length
                    let u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr], { type: 'application/pdf' } );
                    const url = URL.createObjectURL(blob);

                    if(isDownloadRpt){
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = key + ".pdf";
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    } else { 
                        window.open(url, '_blank'); 
                        URL.revokeObjectURL(url);   
                    }   
                }                  
            };   
            return true;                     
        }

        return false;
    }

    const loadModalDropdownOption = async() => {

        await printQueueRepo.getPrintQueueForCombox().then(
            printQueues => {
                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    docPrintCriteria: {
                        ...prevState.docPrintCriteria,
                        invPrinter: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                        suppPrinter: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

        await remoteFileInfoRepo.getFileLoc().then(
            fileLocs => {
                let fileLocationDropdownOptions = fileLocs?.map((fileLoc) => ({
                    dropdownLabel: fileLoc,
                    tagLabel: fileLoc,
                    value: fileLoc,
                })) ?? []
                fileLocationDropdownOptions = _.orderBy(fileLocationDropdownOptions, "dropdownLabel");

                docEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fileLocationDropdownOptions: fileLocationDropdownOptions,
                    },
                    docPrintCriteria: {
                        ...prevState.docPrintCriteria,
                        invFileLoc: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                        suppFileLoc: fileLocationDropdownOptions.length > 0 ? fileLocationDropdownOptions[0].value : '',
                    }
                }))
            }
        )
    }

    const onToggleCustomSetting = () => {

        docEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isShowPrintPad: false,
                docPrintCriteria: {
                    ...prevState.docPrintCriteria,
                    enableCustomSetting: !prevState.docPrintCriteria.enableCustomSetting
                }                       
            }
        })
    }

    const validateSearchCriteria = (searchCriteria: DocumentEnquiryCriteria) => {
        if(searchCriteria){

            for (const key in searchCriteria) {
                if (searchCriteria.hasOwnProperty(key)) {
                    const value = searchCriteria[key];
                    if (value !== null 
                        && value !== "" 
                        && !(Array.isArray(value) && value.length === 0) 
                        && !(typeof value === "boolean" && value === false)) {
                        return false; 
                        }
                }
            }
            return true;
        }
        return true;
    }

    return {
        showLoading: showLoading,
        hideLoading: hideLoading,
        onRefresh: onRefresh,
        onWSMessage: onWSMessage,
        onSwitchSearchCriteriaMode,
        onCriteriaDirectoryDropdown: onCriteriaDirectoryDropdown,
        onCloseScreen: onCloseScreen,
        updateCheckboxRef: updateCheckboxRef,
        updateCheckboxValue: updateCheckboxValue,
        onSaveCheckboxValue: onSaveCheckboxValue,
        onResetCheckboxValue: onResetCheckboxValue,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onHideRightCriteriaPanel,
        onEditSearchCriteria: onEditSearchCriteria,
        openContainerDetail: openContainerDetail,
        closeContainerDetail: closeContainerDetail,
        onSearch,
        fetchTableData: fetchTableData,
        loadDropdownOption: loadDropdownOption,
        onDocumentActive: onDocumentActive,
        onCoVslVoyChange: onCoVslVoyChange,
        onDatePickerChange: onDatePickerChange,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onModalDropdownChange: onModalDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onCheckboxChange: onCheckboxChange,
        onMultipleCheckboxChange: onMultipleCheckboxChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onModalGroupCheckboxChange: onModalGroupCheckboxChange,
        onRadioChange: onRadioChange,
        onSliderRangeChange: onSliderRangeChange,
        onInputTextChange: onInputTextChange,
        onTextAreaChange: onTextAreaChange,
        onExpandableToggleChange: onExpandableToggleChange,
        openSaveNewConfirmModal: openSaveNewConfirmModal,
        openDeleteConfirmModal: openDeleteConfirmModal,
        closeConfirmModal: closeConfirmModal,
        setUserEmail: setUserEmail,
        loadAllSearchPreference: loadAllSearchPreference,
        loadSearchPreference: loadSearchPreference,
        saveNewSearchPreference: saveNewSearchPreference,
        saveSearchPreference: saveSearchPreference,
        deleteSearchPreference: deleteSearchPreference,
        updateSelectedRows: updateSelectedRows,
        onClosePrintModal: onClosePrintModal,
        onReprint: onReprint,
        onPreview: onPreview,
        showPrintPad: showPrintPad,
        prevOrDownloadRpt: prevOrDownloadRpt,
        loadModalDropdownOption: loadModalDropdownOption,
        onModalInputTextChange:onModalInputTextChange,
        onModalCheckboxChange: onModalCheckboxChange,
        onToggleCustomSetting: onToggleCustomSetting,
        validateSearchCriteria: validateSearchCriteria,
    }
}