import { CheckboxProps } from "presentation/model/CheckBoxprops"
import { DocumentEnquiryConstant } from "./DocumentEnquiryConstant"

interface CheckboxPropsList {
    option: CheckboxProps,
    checkboxList?: CheckboxProps[]
}

export const CriteriaDirectoryAllCheckboxOption: CheckboxProps = { key: 'all', name: 'All' }

export const CriteriaDirectoryVoyageCheckboxOption: CheckboxProps[] = [
    { key: 'voyage', name: DocumentEnquiryConstant.Header.VOYAGE },
    { key: 'coVslVoy', name: DocumentEnquiryConstant.Header.CO_VSL_VOY },
    { key: 'vesselName', name: DocumentEnquiryConstant.Header.VSL_NAME },
    { key: 'etd', name: DocumentEnquiryConstant.Header.ETD_RANGE },
]

export const CriteriaDirectoryVoyageCheckboxOptionList: CheckboxPropsList = {
    option: { key: 'voyage', name: DocumentEnquiryConstant.Header.VOYAGE },
    checkboxList: CriteriaDirectoryVoyageCheckboxOption,
}

export const CriteriaDirectoryChargeCheckboxOption: CheckboxProps[] = [
    { key: 'charge', name: DocumentEnquiryConstant.Header.CHARGE },
    { key: 'opsDate', name: DocumentEnquiryConstant.Header.OPS_DATE_RANGE },
    { key: 'billToCompList', name: DocumentEnquiryConstant.Header.BILL_TO_COMPANY },
    { key: 'chgTypeList', name: DocumentEnquiryConstant.Header.CHARGE_TYPE },
    { key: 'subChgTypeList', name: DocumentEnquiryConstant.Header.SUB_CHARGE_TYPE },
    { key: 'billingTypeList', name: DocumentEnquiryConstant.Header.BILLING_TYPE },
    { key: 'billingCycleCode', name: DocumentEnquiryConstant.Header.BILLING_CYCLE_CODE },
    { key: 'billingCycleList', name: DocumentEnquiryConstant.Header.BILL_CYCLE },
    { key: 'customerCodeList', name: DocumentEnquiryConstant.Header.CUST_CODE },
    { key: 'handlingTmlList', name: DocumentEnquiryConstant.Header.HANDLING_TML },
    { key: 'poNumber', name: DocumentEnquiryConstant.Header.PO_NUMBER },

]

export const CriteriaDirectoryChargeCheckboxOptionList: CheckboxPropsList = {
    option: { key: 'charge', name: DocumentEnquiryConstant.Header.CHARGE },
    checkboxList: CriteriaDirectoryChargeCheckboxOption,
}

export const CriteriaDirectoryDocumentCheckboxOption: CheckboxProps[] = [
    { key: 'document', name: DocumentEnquiryConstant.Header.DOCUMENT },
    { key: 'stateList', name: DocumentEnquiryConstant.Header.STATE },
    { key: 'invDate', name: DocumentEnquiryConstant.Header.INVOICE_DATE_RANGE },
    { key: 'invNo', name: DocumentEnquiryConstant.Header.INV_CRE_NOTE_NO },
    { key: 'oriDocNo', name: DocumentEnquiryConstant.Header.ORI_DOC_NO },
    { key: 'previewRefNo', name: DocumentEnquiryConstant.Header.PREVIEW_REF_NO },
    { key: 'origDocNo', name: DocumentEnquiryConstant.Header.ORI_DOC_NO },
    { key: 'docTypeList', name: DocumentEnquiryConstant.Header.DOC_TYPE },
    { key: 'genDate', name: DocumentEnquiryConstant.Header.GEN_DATE_RANGE },
    { key: 'generatedBy', name: DocumentEnquiryConstant.Header.GEN_BY },
    { key: 'issuedBy', name: DocumentEnquiryConstant.Header.ISSUED_BY },
    { key: 'issueByEdiList', name: DocumentEnquiryConstant.Header.EXP_TYPE },
    { key: 'transactionType', name: DocumentEnquiryConstant.Header.TRANSACTION_TYPE },
    { key: 'taxCodeList', name: DocumentEnquiryConstant.Header.TAX_CODE },
    { key: 'taxDate', name: DocumentEnquiryConstant.Header.TAX_DATE_RANGE },
    { key: 'taxPaymentTerm', name: DocumentEnquiryConstant.Header.TAX_PAYMENT_TERM },
    { key: 'publicAtWebSiteList', name: DocumentEnquiryConstant.Header.PUB_WEB_SITE },
    { key: 'approvalStatusList', name: DocumentEnquiryConstant.Header.APPR_STATUS },
    { key: 'approvalDatetime', name: DocumentEnquiryConstant.Header.APPR_DATE_RANGE },

]

export const CriteriaDirectoryDocumentCheckboxOptionList: CheckboxPropsList = {
    option: { key: 'document', name: DocumentEnquiryConstant.Header.DOCUMENT },
    checkboxList: CriteriaDirectoryDocumentCheckboxOption,
}

export const CriteriaDirectoryContainerCheckboxOption: CheckboxProps[] = [
    { key: 'container', name: DocumentEnquiryConstant.Header.CONTAINER },
    { key: 'handlingTmlList', name: DocumentEnquiryConstant.Header.HANDLING_TML },
]

export const CriteriaDirectoryContainerCheckboxOptionList: CheckboxPropsList = {
    option: { key: 'container', name: DocumentEnquiryConstant.Header.CONTAINER },
    checkboxList: CriteriaDirectoryContainerCheckboxOption,
}

export const CriteriaDirectoryTariffCoaCheckboxOption: CheckboxProps[] = [
    { key: 'tariffCoa', name: DocumentEnquiryConstant.Header.TARIFF_COA },
    { key: 'tariffTypeList', name: DocumentEnquiryConstant.Header.TAR_TYPE },
    { key: 'tariffCodeList', name: DocumentEnquiryConstant.Header.TAR_CODE },
    { key: 'costCentreCode', name: DocumentEnquiryConstant.Header.COST_CENTRE_CODE },
    { key: 'revenueCompanyCode', name: DocumentEnquiryConstant.Header.REVENUE_COMPANY_CODE },
    { key: 'productServiceCode', name: DocumentEnquiryConstant.Header.PRODUCT_SERVICE_CODE },
    { key: 'interCompanyCode', name: DocumentEnquiryConstant.Header.INTER_COMPANY_CODE },
    { key: 'salesChannelCode', name: DocumentEnquiryConstant.Header.SALES_CHANNEL_CODE },
    { key: 'revenueAcCode', name: DocumentEnquiryConstant.Header.PRODUCT_SERVICE_CODE },
]

export const CriteriaDirectoryTariffCoaCheckboxOptionList: CheckboxPropsList = {
    option: { key: 'tariffCoa', name: DocumentEnquiryConstant.Header.TARIFF_COA },
    checkboxList: CriteriaDirectoryTariffCoaCheckboxOption,
}

export const CriteriaDirectoryCheckboxOptionList: CheckboxPropsList[] = [
    CriteriaDirectoryVoyageCheckboxOptionList,
    CriteriaDirectoryChargeCheckboxOptionList,
    CriteriaDirectoryTariffCoaCheckboxOptionList,
    CriteriaDirectoryDocumentCheckboxOptionList,
    //CriteriaDirectoryContainerCheckboxOptionList,
]