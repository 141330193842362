import { CustomCellRendererProps } from "ag-grid-react";
import { DocumentHeaderEntity } from "domain/entity/Document/DocumentHeaderEntity";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { DocumentEnquiryConstant } from "./DocumentEnquiryConstant";

const DOC_ENQ_CONST = DocumentEnquiryConstant.Header;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let timeFieldList: string[] = [];
let numberFieldList: string[] = [];

export const INITIAL_DOCUMENT_ENQUIRY_COL_DEF: any[] = [

    {
        headerName: DOC_ENQ_CONST.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: DOC_ENQ_CONST.INVOICE_NO,
        field: 'docNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.PREVIEW_REF_NO,
        field: 'id',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.INVOICE_DATE,
        field: 'docDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "dateTime",
    },
    {
        headerName: DOC_ENQ_CONST.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.CUST_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.ETD,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "dateTime",
    },
    {
        headerName: DOC_ENQ_CONST.VSL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date",
    },
    {
        headerName: DOC_ENQ_CONST.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_CONST.DRAFT_INVOICE_NO,
        field: 'draftDocNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.SUB_CHARGE_TYPE,
        field: 'subChargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.PUB_WEB_SITE,
        field: 'publishAtWebsiteInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_CONST.EXP_TYPE,
        field: 'exportType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.GEN_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.ISSUED_BY,
        field: 'updatedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILLING_TYPE,
        field: 'billingType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.ORI_DOC_NO,
        field: 'originalDocNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: DOC_ENQ_CONST.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_CYCLE,
        field: 'billingCycle',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.INV_CURR,
        field: 'convertedCurrencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: DOC_ENQ_CONST.TTL_AMT_IN_CURR,
        field: 'totalAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOC_ENQ_CONST.GEN_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "dateTime",
    },
    {
        headerName: DOC_ENQ_CONST.LOCAL_CURR,
        field: 'localCurrencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.TTL_AMT_IN_LOCAL_CURR,
        field: 'localTotalAmt',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOC_ENQ_CONST.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.TTL_AMT_IN_CONT_CURR,
        field: 'totalAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: DOC_ENQ_CONST.DOC_STATE,
        field: 'hdrState',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_ADDR_1,
        field: 'billingAddress1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_ADDR_2,
        field: 'billingAddress2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_ADDR_3,
        field: 'billingAddress3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_2ND_ADDR_1,
        field: 'statementAddress1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_2ND_ADDR_2,
        field: 'statementAddress2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.BILL_2ND_ADDR_3,
        field: 'statementAddress3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.PONO,
        field: 'poNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.YOUR_REF,
        field: 'yourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.OUR_REF,
        field: 'ourRef',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.SL_VESSEL_CODE,
        field: 'slVesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.SL_IB_VOYAGE_CODE,
        field: 'slIbVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.SL_OB_VOYAGE_CODE,
        field: 'slObVoyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.APPR_BY,
        field: 'approvedBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: DOC_ENQ_CONST.APPR_DATE,
        field: 'approvedDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        dataType: "dateTime",
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.APPR_STATUS,
        field: 'approvalStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 300,
    },
    {
        headerName: DOC_ENQ_CONST.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.COST_CENTRE_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.REVENUE_COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.PRODUCT_SERVICE_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.REVENUE_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.TRANSACTION_TYPE,
        field: 'transactionType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: DOC_ENQ_CONST.TAX_CODE,
        field: 'taxCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: DOC_ENQ_CONST.TAX_DATE,
        field: 'adjustmentDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: DOC_ENQ_CONST.TAX_PAYMENT_TERM,
        field: 'adjDateAdjQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    //cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, timeFieldList, cellRenderers, numberFieldList);
});

export const transferRowData = (data: DocumentHeaderEntity[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    //externalFnctions['dtlState'] = calculateStateColor;

    return transferRowDataInternal<DocumentHeaderEntity>(data, dateFieldList, dateTimeFieldList, timeFieldList, externalFnctions, numberFieldList);
}