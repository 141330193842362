import { CriteriaDirectoryVoyageCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useMemo } from "react";
import {
    HPHGroupTitle,
    HPHInputMask,
    InputField
} from "veronica-ui-component/dist/component/core";


export const DocumentEnquiryCriteriaEditVoyagePanel = () => {
    //const CHARGE_CONSTANT = ChargeConstant.Charge;
    const DOC_ENQ_HDR_CONST = DocumentEnquiryConstant.Header;
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryVoyageCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria]);
    const memoCoVslVoy = useMemo(() =>
            <div className='im-flex-row-item'>
                <HPHInputMask
                    label={DOC_ENQ_HDR_CONST.CO_VSL_VOY}
                    width="330px"
                    value={{
                        co: searchCriteria.co ?? '',
                        vsl: searchCriteria.vsl ?? '',
                        voy: searchCriteria.voy ?? ''
                    }}
                    fieldConfiguration={{
                        co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                        vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                        voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    }}
                    onDataChange={(e: any) => docEnquiryVM.onCoVslVoyChange(e, {
                        co: 'co',
                        vsl: 'vsl',
                        voy: 'voy'
                    })}/>
            </div>
        , [searchCriteria.co, searchCriteria.vsl, searchCriteria.voy, docEnquiryVM, DOC_ENQ_HDR_CONST.CO_VSL_VOY])
    const memoVesselName = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.VSL_NAME}
                type="text"
                value={searchCriteria.vesselName || ''}
                onChange={(e) => docEnquiryVM.onInputTextChange(e, 'vesselName')} />
        </div>
        , [DOC_ENQ_HDR_CONST.VSL_NAME, searchCriteria.vesselName, docEnquiryVM])
    const memoEtdFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={DOC_ENQ_HDR_CONST.ETD_RANGE}
                    width="330px"
                    fieldNames={{startField:"etdDateFrom", endField:"etdDateTo"}}
                    dateRange={{startDate:searchCriteria.etdDateFrom, endDate: searchCriteria.etdDateTo}}
                    onDatesChange={docEnquiryVM.onDateRangeChange}
                />
            </div>
        , [searchCriteria.etdDateFrom,searchCriteria.etdDateTo, docEnquiryVM, DOC_ENQ_HDR_CONST.ETD_RANGE])
    return (
        enabledCriteria ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle titleText={DOC_ENQ_HDR_CONST.VOYAGE} />
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.vesselName ? memoVesselName : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.etd ? memoEtdFromTo : null}
            </CriteriaItemContainer>
        </div> : null
    );
};
