import { NavigationEntity, SubNavigationEntity, ModalNavigationEntity } from "./index";

export interface NavigationAllEntity {
  navigation?: NavigationEntity[];
  subNavigation?: SubNavigationEntity[];
  chargeSearch?: ModalNavigationEntity[];
  documentSearch?: ModalNavigationEntity[];
  documentItemSearch?: ModalNavigationEntity[];
  proposalSearch?: ModalNavigationEntity[];
  arpitaTemplate?: ModalNavigationEntity[];
}

export interface IMenuEntity extends NavigationAllEntity {
  key: string;
  versionIdentifier: {
    version?: string;
  };
  menuId: string;
}

export const EMPTY_IMENU_ENTITY: IMenuEntity = {
  key: '',
  versionIdentifier: {},
  menuId: '',
}
