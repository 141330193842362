import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { ediExportTypeRadioOptions, yesOrNoRadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { useDocumentEnquiryDetailVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentEnquiryDetailTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHGroupRadioButton, HPHInputMask, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const DocumentEnquiryHeaderFieldFormPanel = () => {  
    const [ anainfoState ] = useANAInfoTracked();
    const docEnqDtlVM = useDocumentEnquiryDetailVM();
    const [ docEnqDtlState ] = useDocumentEnquiryDetailTracked();
    const messageBarVM = useMessageBarVM();    
    
    const { isAdd, isEditable, isSaveClicked, isRead, editingHeader, allFormState} = docEnqDtlState.detailState;
    const {  allowUpdate } = anainfoState;
    const { docHdr } = docEnqDtlState;

    const currentDocHdr = isRead? docHdr: editingHeader;
    const [isLoading, setIsLoading] = useState(false);
    
    const HDR_CONST = DocumentEnquiryConstant.Header;


    
    const handleEdit = useCallback(() => {
        currentDocHdr && docEnqDtlVM.onEdit(currentDocHdr);
    },[currentDocHdr, docEnqDtlVM])

    const handleSave = useCallback(() => {
        setIsLoading(true);
        if(!!!editingHeader){
            return;
        }
        docEnqDtlVM.onSaveClicked();
        docEnqDtlVM.onSave(editingHeader).then((res) => {
            setIsLoading(false);
            docEnqDtlVM.onShowLoading();
            docEnqDtlVM.onSearch(docHdr.id??0).then(() => {
                docEnqDtlVM.onHideLoading();
            })
        
        }).catch(()=>{
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });
        
    },[editingHeader, docEnqDtlVM, docHdr.id, messageBarVM])

    
    const memoDocNo = useMemo(() => 
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentDocHdr.docNo??''}
                fieldValue={currentDocHdr.docNo??''}
                fieldLabel={HDR_CONST.INVOICE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'docNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.INVOICE_NO, allFormState, currentDocHdr.docNo, isSaveClicked])

    const memoDocType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentDocHdr.docType??''}
                fieldValue={currentDocHdr.docType??''}
                fieldLabel={HDR_CONST.DOC_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'docType'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.DOC_TYPE, allFormState, currentDocHdr.docType, isSaveClicked])

    const memoDocDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                disabled={true}
                label={HDR_CONST.INVOICE_DATE}
                width="150px"
                date={currentDocHdr?.docDate}
                fieldName="docDate" 
                onDateChange={()=>{}}/>
        </div>
    , [HDR_CONST.INVOICE_DATE, currentDocHdr?.docDate])

    const memoPrintDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                disabled={true}
                label={HDR_CONST.PRINT_DATE}
                width="150px"
                date={currentDocHdr?.firstPrintDate}
                fieldName="firstPrintDate" 
                onDateChange={()=>{}}/>
        </div>
    , [HDR_CONST.PRINT_DATE, currentDocHdr?.firstPrintDate])

    
    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentDocHdr?.billToCompany || ''}
                fieldValue={currentDocHdr?.billToCompany}
                fieldLabel={HDR_CONST.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_TO_COMPANY, allFormState, currentDocHdr?.billToCompany, isSaveClicked])

    const memoCustCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.customerCode || ''}
                fieldValue={currentDocHdr?.customerCode}
                fieldLabel={HDR_CONST.CUST_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.CUST_CODE, allFormState, currentDocHdr?.customerCode, isSaveClicked])

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item' style={{ marginBottom:"24px"}}>
            <HPHInputMask
                label={HDR_CONST.CO_VSL_VOY}
                width={'330px'}          
                disabled={true}
                value={{
                    co: currentDocHdr?.consortiumCode ?? '',
                    vsl: currentDocHdr?.vesselCode ?? '',
                    voy: currentDocHdr?.voyageCode ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={()=>{}}/>
        </div>
    , [HDR_CONST.CO_VSL_VOY, currentDocHdr?.consortiumCode, currentDocHdr?.vesselCode, currentDocHdr?.voyageCode])

    const memoVslName = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.vesselName || ''}
                fieldValue={currentDocHdr?.vesselName}
                fieldLabel={HDR_CONST.VSL_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vesselName'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.VSL_NAME, allFormState, currentDocHdr?.vesselName, isSaveClicked])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.currencyCode || ''}
                fieldValue={currentDocHdr?.currencyCode}
                fieldLabel={HDR_CONST.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'currencyCode'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.CURRENCY, allFormState, currentDocHdr?.currencyCode, isSaveClicked])

    const memoEtd= useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                disabled={true}
                label={HDR_CONST.ETD}
                width="150px"
                date={currentDocHdr?.etd}
                fieldName="etd" 
                onDateChange={()=>{}}/>
        </div>
    , [HDR_CONST.ETD, currentDocHdr?.etd])

    const memoOpsDate= useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                disabled={true}
                label={HDR_CONST.OPS_DATE}
                width="150px"
                date={currentDocHdr?.opsDate}
                fieldName="opsDate" 
                onDateChange={()=>{}}/>
        </div>
    , [HDR_CONST.OPS_DATE, currentDocHdr?.opsDate])


    const memoDraftDocNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.draftDocNo || ''}
                fieldValue={currentDocHdr?.draftDocNo}
                fieldLabel={HDR_CONST.DRAFT_INVOICE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'draftDocNo'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.DRAFT_INVOICE_NO, allFormState, currentDocHdr?.draftDocNo, isSaveClicked])

    const memoTotalAmt = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"300px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.totalAmount?.toString() || ''}
                fieldValue={currentDocHdr?.totalAmount?.toString()}
                fieldLabel={HDR_CONST.TTL_AMT_IN_CURR}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'totalAmount'}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.TTL_AMT_IN_CURR, allFormState, currentDocHdr?.totalAmount, isSaveClicked])

    const memoBillAddr1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_ADDR_1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_ADDR_1, allFormState, isSaveClicked])

    const memoBillAddr2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_ADDR_2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_ADDR_2, allFormState, isSaveClicked])


    const memoBillAddr3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_ADDR_3}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_ADDR_3, allFormState, isSaveClicked])


    const memoBill2ndAddr1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_2ND_ADDR_1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_2ND_ADDR_1, allFormState, isSaveClicked])

    const memoBill2ndAddr2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_2ND_ADDR_2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_2ND_ADDR_2, allFormState, isSaveClicked])

    const memoBill2ndAddr3 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={''}
                fieldValue={''}
                fieldLabel={HDR_CONST.BILL_2ND_ADDR_3}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                options={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [HDR_CONST.BILL_2ND_ADDR_3, allFormState, isSaveClicked])

    const memoPoNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.poNo || ''}
                fieldValue={currentDocHdr?.poNo}
                fieldLabel={HDR_CONST.PONO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'poNo'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.poNo, HDR_CONST.PONO, isSaveClicked, docEnqDtlVM])


    const memoYourRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.yourRef || ''}
                fieldValue={currentDocHdr?.yourRef}
                fieldLabel={HDR_CONST.YOUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.yourRef, HDR_CONST.YOUR_REF, isSaveClicked, docEnqDtlVM])

    const memoOurRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.ourRef || ''}
                fieldValue={currentDocHdr?.ourRef}
                fieldLabel={HDR_CONST.OUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ourRef'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.ourRef, HDR_CONST.OUR_REF, isSaveClicked, docEnqDtlVM])

    const memoSlVslCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.slVesselCode || ''}
                fieldValue={currentDocHdr?.slVesselCode}
                fieldLabel={HDR_CONST.SL_VESSEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slVesselCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.slVesselCode, HDR_CONST.SL_VESSEL_CODE, isSaveClicked, docEnqDtlVM])

    const memoSlIbVoyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.slIbVoyageCode || ''}
                fieldValue={currentDocHdr?.slIbVoyageCode}
                fieldLabel={HDR_CONST.SL_IB_VOYAGE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slIbVoyageCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.slIbVoyageCode, HDR_CONST.SL_IB_VOYAGE_CODE, isSaveClicked, docEnqDtlVM])

    const memoSlObVoyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentDocHdr?.slObVoyageCode || ''}
                fieldValue={currentDocHdr?.slObVoyageCode}
                fieldLabel={HDR_CONST.SL_OB_VOYAGE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slObVoyageCode'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    docEnqDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, currentDocHdr?.slObVoyageCode, HDR_CONST.SL_OB_VOYAGE_CODE, isSaveClicked, docEnqDtlVM])


    const memoPubWebInd = useMemo(() =>
        <div className='im-flex-row-item'>
           <HPHGroupRadioButton
                label={HDR_CONST.PUB_WEB_SITE}
                radioOptions={yesOrNoRadioOptions}
                value={currentDocHdr.publishAtWebsiteInd??''}
                onChange={(e) => docEnqDtlVM.onRadioChange(e,'publishAtWebsiteInd')}
                checked={yesOrNoRadioOptions.find(item => item.key === currentDocHdr.publishAtWebsiteInd)?.name}
                disabled={isRead}
                />
        </div>
    , [HDR_CONST.PUB_WEB_SITE, currentDocHdr.publishAtWebsiteInd, docEnqDtlVM, isRead])

    const memoIssEdiInd = useMemo(() =>
        <div className='im-flex-row-item'>
           <HPHGroupRadioButton
                label={HDR_CONST.EXP_TYPE}
                radioOptions={ediExportTypeRadioOptions}
                value={currentDocHdr.issueByEdiInd??''}
                onChange={(e) => docEnqDtlVM.onRadioChange(e,'issueByEdiInd')}
                checked={ediExportTypeRadioOptions.find(item => item.key === currentDocHdr.issueByEdiInd)?.name}
                disabled={isRead}
                />
        </div>
    , [HDR_CONST.EXP_TYPE, currentDocHdr.issueByEdiInd, docEnqDtlVM, isRead])


    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{currentDocHdr.docNo??'Header'}</SidebarTitle>
                        <SidebarActionCross>
                                    {(!isAdd && (isEditable && isRead)) &&
                                        <>
                                            {
                                                (
                                                    (isEditable && allowUpdate)
                                                )
                                                && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                            }
                                        </>}
                                    {((isAdd || (isEditable && !isRead))) &&
                                        <>
                                        
                                            {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-tick" size="medium" disabled={isRead} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={handleSave} />
                                            }
                                        </>}
                                </SidebarActionCross>

                    </Sidebarheader>
                </div>
            </div> 
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <>
            <div className={'add-edit-form'} style={{ height:'78vh', maxHeight:'78vh' , overflow: 'auto'}}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <CriteriaItemContainer>
                    {memoDocNo}{memoDocType}{memoBillToCompany}
                    </CriteriaItemContainer>      
                    <CriteriaItemContainer>
                    {memoDraftDocNo} {memoDocDate}{memoPrintDate} 
                    </CriteriaItemContainer>            
                    <CriteriaItemContainer>
                    {memoEtd} {memoOpsDate}{memoCustCode}
                    </CriteriaItemContainer>      
                    <CriteriaItemContainer>
                    {memoCoVslVoy} {memoVslName} 
                    </CriteriaItemContainer>            
                        
                    <CriteriaItemContainer>
                    {memoCurrency}{memoTotalAmt}
                    </CriteriaItemContainer> 
                    <CriteriaItemContainer>
                        {memoBillAddr1}{memoBill2ndAddr1}
                    </CriteriaItemContainer>       
                    
                    <CriteriaItemContainer>
                        {memoBillAddr2}{memoBill2ndAddr2}
                    </CriteriaItemContainer>   

                    <CriteriaItemContainer>
                        {memoBillAddr3}{memoBill2ndAddr3}
                    </CriteriaItemContainer>                
                    <CriteriaItemContainer>
                        {memoPoNo}{memoYourRef}{memoOurRef}
                    </CriteriaItemContainer>                    
                    <CriteriaItemContainer>
                        {memoSlVslCode}{memoSlIbVoyCode}{memoSlObVoyCode}
                    </CriteriaItemContainer>           
                    <CriteriaItemContainer>
                        {memoPubWebInd}
                        {memoIssEdiInd}
                    </CriteriaItemContainer>  
                </div>                  
            </div>
        </div>
            </>
        </div>
    </>

}
