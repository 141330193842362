import { chargeCategoryCheckboxOption, docAppStatusCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { CriteriaDirectoryDocumentCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useMemo } from "react";
import {
    GroupCheckboxList,
    HPHGroupCheckbox,
    HPHGroupTitle,
    InputDropdown,
    InputField
} from "veronica-ui-component/dist/component/core";


export const DocumentEnquiryCriteriaEditDocumentPanel = () => {
    const DOC_ENQ_HDR_CONST = DocumentEnquiryConstant.Header;
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria;
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryDocumentCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria]);

    const memoState = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.STATE}
                    checkboxData={[
                        { key: "NIL", name: "NIL", disabled: false },
                        { key: "INV", name: "INV", disabled: false }
                    ]}
                    selectedValues={searchCriteria.stateList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "stateList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.STATE, searchCriteria, docEnquiryVM])

    const memoOriDocNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.ORI_DOC_NO}
                type="text"
                value={searchCriteria.oriDocNo || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'oriDocNo')} />
        </div>
        , [DOC_ENQ_HDR_CONST.ORI_DOC_NO, searchCriteria.oriDocNo, docEnquiryVM])

    const memoDocType = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.DOC_TYPE}
                    checkboxData={[
                        { key: "IN", name: "IN", disabled: false },
                        { key: "CN", name: "CN", disabled: false }
                    ]}
                    selectedValues={searchCriteria.docTypeList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "docTypeList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.DOC_TYPE, searchCriteria, docEnquiryVM])

    const memoInvNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.INV_CRE_NOTE_NO}
                type="text"
                value={searchCriteria.invNo || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'invNo')} />
        </div>
        , [searchCriteria.invNo, docEnquiryVM, DOC_ENQ_HDR_CONST.INV_CRE_NOTE_NO])
    const memoPreviewRefNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                label={DOC_ENQ_HDR_CONST.PREVIEW_REF_NO}
                width='330px'
                maxLength={999}
                fieldName={"previewRefNo"}
                value={searchCriteria.previewRefNo || ''}
                disabled={false}
                decimalPlaces={0}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'previewRefNo')}
            />
        </div>
        , [searchCriteria.previewRefNo, docEnquiryVM, DOC_ENQ_HDR_CONST.PREVIEW_REF_NO])
    const memoInvDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOC_ENQ_HDR_CONST.INVOICE_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "invDateFrom", endField: "invDateTo" }}
                dateRange={{ startDate: searchCriteria.invDateFrom, endDate: searchCriteria.invDateTo }}
                onDatesChange={docEnquiryVM.onDateRangeChange}
            />
        </div>
        , [searchCriteria.invDateFrom, searchCriteria.invDateTo, docEnquiryVM, DOC_ENQ_HDR_CONST.INVOICE_DATE_RANGE])

    const memoGenDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOC_ENQ_HDR_CONST.GEN_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "genDateFrom", endField: "genDateTo" }}
                dateRange={{ startDate: searchCriteria.genDateFrom, endDate: searchCriteria.genDateTo }}
                onDatesChange={docEnquiryVM.onDateRangeChange}
            />
        </div>
        , [DOC_ENQ_HDR_CONST.GEN_DATE_RANGE, searchCriteria.genDateFrom, searchCriteria.genDateTo, docEnquiryVM.onDateRangeChange])

    const memoGenBy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.GEN_BY}
                type="text"
                value={searchCriteria.generatedBy || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'generatedBy')} />
        </div>
        , [DOC_ENQ_HDR_CONST.GEN_BY, searchCriteria.generatedBy, docEnquiryVM])

    const memoIssueBy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.ISSUED_BY}
                type="text"
                value={searchCriteria.issuedBy || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'issuedBy')} />
        </div>
        , [DOC_ENQ_HDR_CONST.ISSUED_BY, searchCriteria.issuedBy, docEnquiryVM])

    const memoPubWeb = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.PUB_WEB_SITE}
                    checkboxData={[
                        { key: "Y", name: "Y", disabled: false },
                        { key: "N", name: "N", disabled: false }
                    ]}
                    selectedValues={searchCriteria.publicAtWebSiteList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "publicAtWebSiteList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.PUB_WEB_SITE, searchCriteria, docEnquiryVM])



    const memoIssuedEdi = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.EXP_TYPE}
                    checkboxData={chargeCategoryCheckboxOption}
                    selectedValues={searchCriteria.issueByEdiList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "issueByEdiList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.EXP_TYPE, searchCriteria, docEnquiryVM])



    const memoAppStatus = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.APPR_STATUS}
                    checkboxData={docAppStatusCheckboxOption}
                    selectedValues={searchCriteria.approvalStatusList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "approvalStatusList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.APPR_STATUS, searchCriteria, docEnquiryVM])


    const memoApprDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOC_ENQ_HDR_CONST.APPR_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "approvalDatetimeFrom", endField: "approvalDatetimeTo" }}
                dateRange={{ startDate: searchCriteria.approvalDatetimeFrom, endDate: searchCriteria.approvalDatetimeTo }}
                onDatesChange={docEnquiryVM.onDateRangeChange}
            />
        </div>
        , [DOC_ENQ_HDR_CONST.APPR_DATE_RANGE, searchCriteria.approvalDatetimeFrom, searchCriteria.approvalDatetimeTo, docEnquiryVM.onDateRangeChange])

    const memoTransactionType = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.TRANSACTION_TYPE}
                type="text"
                value={searchCriteria.transactionType || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'transactionType')} />
        </div>
        , [DOC_ENQ_HDR_CONST.TRANSACTION_TYPE, searchCriteria.transactionType, docEnquiryVM])

    const memoTaxCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={DOC_ENQ_HDR_CONST.TAX_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.taxCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => docEnquiryVM.onMultipleDropdownChange(e, 'taxCodeList')}
                options={docEnquiryState.dynamicOptions.taxCodeDropdownOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.TAX_CODE, searchCriteria.taxCodeList, docEnquiryState.dynamicOptions.taxCodeDropdownOptions, docEnquiryVM])

    const memoTaxDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOC_ENQ_HDR_CONST.TAX_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "taxDateFrom", endField: "taxDateTo" }}
                dateRange={{ startDate: searchCriteria.taxDateFrom, endDate: searchCriteria.taxDateTo }}
                onDatesChange={docEnquiryVM.onDateRangeChange}
            />
        </div>
        , [DOC_ENQ_HDR_CONST.TAX_DATE_RANGE, searchCriteria.taxDateFrom, searchCriteria.taxDateTo, docEnquiryVM.onDateRangeChange])

    const memoTaxPaymentTerm = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                label={DOC_ENQ_HDR_CONST.TAX_PAYMENT_TERM}
                width="330px"
                fieldName={"taxPaymentTerm"}
                value={searchCriteria.taxPaymentTerm || ''}
                disabled={false}
                decimalPlaces={0}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'taxPaymentTerm')}
            />
        </div>
        , [DOC_ENQ_HDR_CONST.TAX_PAYMENT_TERM, searchCriteria.taxPaymentTerm, docEnquiryVM])

    return (
        enabledCriteria ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle titleText={DOC_ENQ_HDR_CONST.DOCUMENT} />
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.invNo ? memoInvNo : null}
                {docEnquiryState.enabledSearchCriteria.oriDocNo ? memoOriDocNo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.previewRefNo ? memoPreviewRefNo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.stateList ? memoState : null}
                {docEnquiryState.enabledSearchCriteria.docTypeList ? memoDocType : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.invDate ? memoInvDateFromTo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.genDate ? memoGenDateFromTo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.generatedBy ? memoGenBy : null}
                {docEnquiryState.enabledSearchCriteria.issuedBy ? memoIssueBy : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.issueByEdiList ? memoIssuedEdi : null}
                {docEnquiryState.enabledSearchCriteria.publicAtWebSiteList ? memoPubWeb : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.transactionType ? memoTransactionType : null}
                {docEnquiryState.enabledSearchCriteria.taxCodeList ? memoTaxCode : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.taxDate ? memoTaxDateFromTo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.taxPaymentTerm ? memoTaxPaymentTerm : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.approvalStatusList ? memoAppStatus : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {docEnquiryState.enabledSearchCriteria.approvalDatetime ? memoApprDateFromTo : null}
            </CriteriaItemContainer>
        </div> : null
    );
};
